<template>
  <div v-if="!positionId">
    <div
      v-if="positions.length > 0"
      class="positions-tab"
    >
      <PositionListItem
        v-for="position in positions"
        :key="position.id"
        :position="position"
        @click="positionClickHandler(position)"
      />
    </div>
  </div>

  <div v-else>
    <ui-button
      :type="2"
      @click="$router.push({name:'Terminal — Positions'})"
    >
      Back
    </ui-button>

    <ui-button
      :type="2"
      @click="cancelPositionHandler"
    >
      Cancel position
    </ui-button>
    aaaaaaaa {{ positionId }}
  </div>
</template>

<script>

import UiButton from '../ui/UiButton'
import PositionListItem from '../components/PositionListItem.vue'
import { usePositionsStore } from '../store/positions'
import { useChartStore } from '@/terminal/store/chart'
import api from '@/terminal/api'

const positionsStore = usePositionsStore()
const chartStore = useChartStore()

export default {
  components: {
    UiButton,
    PositionListItem,
  },

  data() {
    return {
      positionsStore,
    }
  },

  computed: {
    positions() {
      return positionsStore.getPositions
    },

    positionId() {
      return this.$route.params.positionId
    },
  },

  mounted() {
    // positionsStore.fetch()
  },

  methods: {
    positionClickHandler(position) {
      console.log('----> position', position)
    },

    async cancelPositionHandler() {
      await api.cancelPosition(this.positionId)
    },

    gotoPosition(position) {
      let finalDate = Math.max(position.take_date, position.stop_date)

      if (!finalDate) {
        finalDate = chartStore.realtimeCandle.date
      }

      chartStore.setBrokerData({
        startDate: position.open_date,
        endDate: finalDate,
      })
      chartStore.setChartRange([position.open_date, finalDate])
    },
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/global.styl'

// .positions-tab

</style>
