<template>
  <RouterLink
    :to="{name: 'Terminal — Position', params: {positionId: position.id}}"
    class="position-list-item"
    @click="$emit('click', position)"
  >
    <div class="position-list-item__row">
      <span class="text-1">
        {{ position.ticker }}

        <span
          class="dir"
          :class="'dir--' + direction"
        >{{ direction }}</span>
      </span>
      <span
        class="text-2"
        :class="'text--' + profitClass"
      >
        {{ profit }}
      </span>
    </div>
    <div class="position-list-item__row">
      <span class="text-3">
        {{ openDate }}
      </span>
      <span class="text-4">
        {{ position.state }}
      </span>
    </div>
  </RouterLink>
</template>

<script>

import { format } from 'fecha'

export default {
  components: {

  },

  props: {
    /**
     * Position
     */
    position: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {

    }
  },

  computed: {
    openDate() {
      return format(new Date(this.position.open_date), 'DD.MM.YY hh:mm')
    },

    profit() {
      if (isNaN(parseInt(this.position.profit))) {
        return this.position.profit
      }

      return `$${Math.abs(this.position.profit).toFixed(2)}`
    },

    profitClass() {
      if (this.position.profit > 0) {
        return 'positive'
      }

      if (this.position.profit < 0) {
        return 'negative'
      }

      return 'default'
    },

    direction() {
      return this.position.direction > 0 ? 'buy' : 'sell'
    },
  },

  mounted() {

  },

  methods: {

  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/global.styl'

.position-list-item
  display block
  position relative
  padding 9px 0
  text-decoration none
  color var(--c-labels-primary)

  &__row
    display flex
    justify-content space-between
    font-weight 300

    .text-1, .text-2
      font-size 17px
      line-height 22px

    .text-3, .text-4
      color var(--c-labels-secondary)
      font-size 15px
      line-height 20px

    .text--positive
      color var(--c-tints-system-green)

    .text--negative
      color var(--c-tints-system-red)

    .dir
      margin-left 4px
      font-size 11px
      letter-spacing 0.5px
      font-weight 400
      line-height 1
      text-transform uppercase
      background var(--c-fills-tertiary)
      padding 2px 4px
      border-radius 4px
      opacity 0.75

      &--sell
        color var(--c-tints-system-red)

      &--buy
        color var(--c-tints-system-green)

  &:before
    position absolute
    content ''
    height 1px
    width calc(100% + 16px)
    margin-top -9px
    background-color var(--c-separators-3)

  &:first-child
    &:before
      display none

</style>
